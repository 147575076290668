import React, { useEffect, useState } from "react";

import AwardCard from "../AwardCard";
import EmptyScreen from "../EmptyScreen";
import "./awards.css";

const Awards = (props) => {
   
    let { data } = props.data

  
  return (
    <div className="Awards-container">
      <div className="awards-heading">Your Awards ({data.total})</div>
      {
          data.awardList.map((awardData, index)=>
              <AwardCard awardData = {awardData}  value={index} />
          )
      }


    
    </div>
  );
};

export default Awards;
