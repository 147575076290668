import React, { useEffect, useState } from "react";
import "./App.css";
import headImg from "./assets/headImg.png";
import classplusLogo from "./assets/classplusLogo.png"

import Awards from "./components/Awards";
import EmptyScreen from "./components/EmptyScreen";

import axios from "axios";
import HowToWin from "./components/HowToWin";

function App() {
  const [Awardsdata, setAwardsdata] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [response, setresponse] = useState(false);

  const [howToWin, sethowToWin] = useState(false);

  const [Toaster, setToaster] = useState(true)

  const [count, setCount] = useState()

 

  const makeApiCall = async (token) => {
    axios
      .get(
        'https://api.classplusapp.com/v2/course/founderDesk/awardList',
        {
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => {
         setAwardsdata(data.data);
        
        setisLoading(false);
         setCount(data.data.data.total)
        
      

       
      })
      .catch((error) => {
          console.log(error)
      });
  };

  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var token = url.searchParams.get("token");
    localStorage.setItem('token', token);
    if (token) {
  
      makeApiCall(token);
     
    }
  }, []);

  const wrapperStyle = {
    position: "absolute",
    backgroundColor: "black",
    height: "100vh",
    width: "100%",
    opacity: "0.6",
    top: "0",
    left: "0",
  };

console.log(isLoading,"isloading")

  return (
    <>
      <div id="wrapper" style={howToWin ? wrapperStyle : null}></div>
      <div className="container">


        {/* <img src={headImg} id="head-banner" />
        <div id="howToWin-text">How to win ?</div> */}


        <div id="head-banner">
          <div id="banner-texts">
          <img src={classplusLogo} id="classplus-logo"/>
          <div id="banner-subheading">See the awards you’ve won & share them with the world!</div>
          <div id="howToWin-text" onClick={()=>sethowToWin(true)}>How to win ?</div>
          </div>
        </div>

        <div className="bottom-sheet">
          <div className="bottomsheet-data">
            {isLoading ? (
              <div id="loading">loading...</div>
            ) : count >0  && !howToWin ? (
              <Awards data={Awardsdata} howToWin={howToWin} sethowToWin={sethowToWin} />
            ) : 
            howToWin ? (
              <HowToWin  sethowToWin={sethowToWin} /> ):
               count ==0 ? 
              <EmptyScreen howToWin={howToWin} sethowToWin={sethowToWin} />
          :""
            }
          </div>
        </div>
      </div>

    </>
  );
}

export default App;
