import award1 from "../assets/award1.png"
import award2 from "../assets/award2.png"
import award3 from "../assets/award3.png"
import award4 from "../assets/award4.png"
import award5 from "../assets/award5.png"
import award6 from "../assets/award6.png"
import award7 from "../assets/award7.png"
import award8 from "../assets/award8.png"




export const awardsDescription = [
    // {
    //     awardIcon : award1,
    //     awardName : "Student’s Mentor Award",
    //     awardDescription: "DFH/FH/CM/LT Cohort wise Top 20% teachers in terms of GMV "
    // },
    {
        awardIcon : award2,
        awardName : "Digital Educator Award",
        awardDescription: "Teach your students with engaging Live classes regularly to help them succeed. Conduct 15 or more hours of Live classes in a month to win this award."
    },
    {
        awardIcon : award3,
        awardName : "Engagement Guru Award",
        awardDescription: "Make sure to engage with your students and get regular feedback with polls. Conduct 3 or more polls in a month to win this award."
    },
    {
        awardIcon : award4,
        awardName : "EdTech Entrepreneur Award",
        awardDescription: "Publish updated courses regularly to your store and grow every month. Sell 10 or more courses in a month to win this award."
    },
    {
        awardIcon : award5,
        awardName : "Live Master Award",
        awardDescription: "Listen to the students' needs and create Live courses for most effective learning. Publish 2 or more Live courses in a month to win this award."
    },
    {
        awardIcon : award6,
        awardName : "Students' Mentor Award",
        awardDescription: "Lead the way in online education by guiding more & more students from across the world. Be among the top 20% of our educators in terms of revenue for the month, to win this award."
    },
    {
        awardIcon : award7,
        awardName : "Students' Choice Award",
        awardDescription: "Let your students be the brand ambassadors of your coaching. Receive 5 or more testimonials from your students in a month to win this award."
    },
    {
        awardIcon : award8,
        awardName : "Emerging Educator Award",
        awardDescription: "Stock your store with new and updated courses to enroll more student subscribers. Get your highest ever store transactions in a month to break your monthly record and win this award."
    },
        {
        awardIcon : award1,
        awardName : "Super Achiever Award",
        awardDescription: "Records are meant to be broken. Break your own record for the highest revenue in a month and win this award."
    },
    
]