import React from 'react'

import "./awardDescription.css"

const AwardDescription = (props) => {
    let { award } = props
    console.log(award)
    return (
        <div className="award-card">
            <div className="card-heading">
                <img src={award.awardIcon} id="card-awardIcon"/>
                <p id="award-title">{award.awardName}</p>
            </div>
            <p id="card-description">{award.awardDescription}</p>
        </div>
    )
}

export default AwardDescription
