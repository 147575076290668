import React, { useState } from "react";
import Awards from "../Awards";
import "./emptyScreen.css";

import emptyImg from "../../assets/empty.png";
import howtowinCTA from "../../assets/howtowinCTA.png";

import HowToWin from "../HowToWin";

const EmptyScreen = (props) => {
  let count = 0;

 

  return (
    <div className="empty-screen">
      {/* {props.howToWin ? (
        <HowToWin sethowToWin = {props.sethowToWin} />
      ) : ( */}
        <>
          <p id="awards-count">Your Awards ({count})</p>
          <div className="empty-data">
            <img src={emptyImg} id="empty-img" />
            <p id="empty-text">
              You haven’t won an award yet. See how you can win!
            </p>
            <img
              src={howtowinCTA}
              id="howtowinCTA"
              onClick={() => props.sethowToWin(true)}
            />
          </div>
        </>
      {/* )} */}
    </div>
  );
};

export default EmptyScreen;
