import React from "react";
import "./howToWin.css";

import AwardDescription from "../AwardDescription";

import { awardsDescription } from "../../utils";

import close from "../../assets/close.png";

const HowToWin = (props) => {
  return (
    <div className="howToWin-container">
      <div className="howToWin-heading">
        How to win
        <img src={close} id="howToWin-close"  onClick={() => props.sethowToWin(false)}/>
      </div>
    <div className="award-description-container">
      {awardsDescription.map((award) => {
        return <AwardDescription award={award} />;
      })}
      </div>
    </div>
  );
};

export default HowToWin;
