import React,{ useEffect } from 'react'

import "./awardCard.css"

import download from "../../assets/Download.png"
import watsapp from "../../assets/Whatsapp.png"
import html2canvas from "html2canvas";
import axios from "axios";

const AwardCard =  ({ awardData, value }) => {



const shareOnWatsapp = (paramTracking, imageUrl, imageName) => {
  
  let token =  localStorage.getItem('token');

 fetch('https://api.classplusapp.com/v2/storeDeeplink', 
// fetch('http://store.sparsh.dev.classplus.co/v2/storeDeeplink', 

{
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    "x-access-token": token

  },
  body: JSON.stringify({
   deeplink:{
    ...paramTracking,
    
   }
    
  })
})

let a = btoa(imageUrl)

if (
  window &&
  window.webkit &&
  window.webkit.messageHandlers &&
  window.webkit.messageHandlers.onDeeplinkExecuted
) {

  window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
    `UTIL_SHARE_IMAGE,${a}, ${imageName}`
  );

}

if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
  window.mobile.onDeeplinkExecuted(`UTIL_SHARE_IMAGE,${a},${imageName}`);
}


};



  

 
const downloadCertificate = (paramTracking, imageUrl, imageName) => {
  

    let token =  localStorage.getItem('token');

     fetch('https://api.classplusapp.com/v2/storeDeeplink'
    // fetch('http://store.sparsh.dev.classplus.co/v2/storeDeeplink'
    
    , {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "x-access-token": token
    
      },
      body: JSON.stringify({
       deeplink:{
         ...paramTracking,
       
       }
        
      })
    })

    let a = btoa(imageUrl)

    if (
      window &&
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.onDeeplinkExecuted
    ) {

      window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
        `UTIL_DOWNLOAD_IMAGE,${a},${imageName}`
      );

    }

    if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
      window.mobile.onDeeplinkExecuted(`UTIL_DOWNLOAD_IMAGE,${a},${imageName}`);
    }




}

    return (
        <div className="awardCard-container" >
            <div id="awardCard-heading-container">
            <img src={awardData.iconURL} id="award-icon"/>
           <div id="awardCard-heading">{awardData.name}</div>
           </div>
        
           <img src={awardData.certificateURL}  className="certificate" id={`certificate${value}`} />
          
            <div id="cta-container">
                <img src={download} id="download-cta"  onClick={()=>downloadCertificate(awardData.downloadParamTracking, awardData.certificateURL, awardData.name)}/>
                <img src={watsapp} id="watsapp-cta"  onClick={()=>shareOnWatsapp(awardData.WAParamTracking, awardData.certificateURL, awardData.name)}/>
            </div>
           
        </div>
    )
}

export default AwardCard
